import { useRoutes } from 'react-router-dom';
import { lazyLoadRoutes } from '../utils/lazy-loader';
import routes from '../constants/routes';

export function AppRoutes() {
    const appRoutes = [
        {
            path: '/',
            children: [
                {
                    index: true,
                    element: lazyLoadRoutes('dashboard', true),
                },
                {
                    path: routes.login,
                    element: lazyLoadRoutes('auth/login'),
                },
                {
                    path: routes.changePassword,
                    element: lazyLoadRoutes('profile/change-password', true),
                },
                {
                    path: routes.products,
                    element: lazyLoadRoutes('product/index', true),
                },
                {
                    path: routes.addProduct,
                    element: lazyLoadRoutes('product/add', true),
                },
                {
                    path: `${routes.addProduct}/:id`,
                    element: lazyLoadRoutes('product/add', true),
                },
                {
                    path: routes.vendors,
                    element: lazyLoadRoutes('vendor/index', true),
                },
                {
                    path: routes.addVendor,
                    element: lazyLoadRoutes('vendor/add', true),
                },
                {
                    path: `${routes.addVendor}/:id`,
                    element: lazyLoadRoutes('vendor/add', true),
                },
                {
                    path: routes.vendorOrders,
                    element: lazyLoadRoutes('vendor/orders', true),
                },
                {
                    path: routes.addVendorOrders,
                    element: lazyLoadRoutes('vendor/add-orders', true),
                },
                {
                    path: `${routes.addVendorOrders}/:id`,
                    element: lazyLoadRoutes('vendor/add-orders', true),
                },
                {
                    path: routes.customers,
                    element: lazyLoadRoutes('customer/index', true),
                },
                {
                    path: routes.addCustomer,
                    element: lazyLoadRoutes('customer/add', true),
                },
                {
                    path: `${routes.addCustomer}/:id`,
                    element: lazyLoadRoutes('customer/add', true),
                },
                {
                    path: routes.customerSummary,
                    element: lazyLoadRoutes('customer/summary', true),
                },
                {
                    path: routes.customerOrder,
                    element: lazyLoadRoutes('customer/orders', true),
                },
                {
                    path: routes.addCustomerOrder,
                    element: lazyLoadRoutes('customer/add-orders', true),
                },
                {
                    path: `${routes.addCustomerOrder}/:id`,
                    element: lazyLoadRoutes('customer/add-orders', true),
                },
                {
                    path: routes.expenses,
                    element: lazyLoadRoutes('expenses/index', true),
                },
                {
                    path: routes.inventory,
                    element: lazyLoadRoutes('inventory/index', true),
                },
                {
                    path: routes.reportProfit,
                    element: lazyLoadRoutes('reports/profit', true),
                },
                {
                    path: routes.reportPurchase,
                    element: lazyLoadRoutes('reports/purchase', true),
                },
                {
                    path: routes.reportSell,
                    element: lazyLoadRoutes('reports/sell', true),
                },
                {
                    path: routes.reportPayment,
                    element: lazyLoadRoutes('reports/payment', true),
                },
                {
                    path: routes.funds,
                    element: lazyLoadRoutes('funds/index', true),
                },
                {
                    path: routes.transactions,
                    element: lazyLoadRoutes('transactions/index', true),
                },
                {
                    path: routes.notFound,
                    element: lazyLoadRoutes('not-found'),
                }
            ]
        },
        {
            path: '*',
            element: lazyLoadRoutes('not-found')
        }
    ];

    return useRoutes(appRoutes);
}